<template>
  <section class="dtc-main-section" id="" style="margin-top:2.5rem">
    <header
      class="dtc-title"
      style="font-size: 20px; display: grid; place-items: start;font-family:DFKai-sb;"
    >
      附件3-1
    </header>
    <header
      class="dtc-title"
      style="font-size: 20px; display: grid; place-items: center;font-family:DFKai-sb;"
    >
      {{ schoolName }}學校{{ bYear }}學年度{{
        term == 1 ? "上" : "下"
      }}學期偏鄉護理菁英公費生印領清冊核銷明細表
      <b-button
        hidden
        id="dtc-print-btn"
        size="sm"
        variant="success"
        @click="save"
        class="mr-2 d-print-none dtc-print-btn2"
        style="display:inline-block;width:100px;height:40px;margin-top:-20px;pointer-events:none;"
      >
        <i class="fas fa-save mr-1"></i>儲存變更
      </b-button>
    </header>
    <header class="dtc-grid-header8 mt-2">
      <div class="dtc-grid-height">項目</div>

      <div class="dtc-grid-height dtc-fill-required">年級</div>
      <div class="dtc-grid-height">學生姓名</div>
      <section class="dtc-heaer-s1">
        <small
          class="comment-one"
          style="font-size : 9px; -webkit-transform : scale(0.75);font-weight: 900;"
          >註1</small
        >
        <div>學雜費(含退輔基金、實驗費)</div>
        <div>學生平安保險費</div>
        <div>教學實習費(含電腦網路使用費)</div>
      </section>
      <section class="dtc-heaer-s2">
        <small
          class="comment-two"
          style="font-size : 9px; -webkit-transform : scale(0.75);font-weight: 900;"
          >註2</small
        >
        <div>膳食費 3250元/月/*6個月</div>
        <div>零用津貼 3500元*6個月</div>

        <div>課業費 1,000元 /每學期</div>
        <div>書籍費 4,000元 /每學期</div>
        <div>制服費 2,500元 /每學期</div>
      </section>
      <section class="dtc-heaer-s3">
        <small
          class="comment-three"
          style="font-size : 9px; -webkit-transform : scale(0.75);font-weight: 900;"
          >註3</small
        >
        <div class="comment-five-head">
          返鄉旅費 (交通費核實支付) 5,000元 /每人每學期
          <small
            class="comment-five"
            style="font-size : 9px; -webkit-transform : scale(0.75);font-weight: 900;"
            >註5</small
          >
        </div>
        <div class="comment-six-head">
          住宿費 3,000元/月/*6個月
          <small
            class="comment-six"
            style="font-size : 9px; -webkit-transform : scale(0.75);font-weight: 900;"
            >註6</small
          >
        </div>
      </section>
      <div class="dtc-grid-height">設備費</div>
      <div class="dtc-grid-height">合計</div>
      <div class="dtc-grid-height" style="border-right:0px">具領人簽章</div>
    </header>
    <main
      class="dtc-grid-main"
      v-for="(item, idx) in map.budgeting_detail"
      :key="item.SchoolCode"
    >
      <div>{{ idx + 1 }}</div>

      <div>{{ item.GradeName }}</div>
      <div>{{ item.Student }}</div>
      <div class="text-right-mode">${{ $formatPrice(item.Fee_1) }}</div>
      <div class="text-right-mode">${{ $formatPrice(item.Fee_2) }}</div>
      <div class="text-right-mode">${{ $formatPrice(item.Fee_3) }}</div>
      <div class="text-right-mode">${{ $formatPrice(item.Fee_4) }}</div>
      <div class="text-right-mode">${{ $formatPrice(item.Fee_5) }}</div>
      <div class="text-right-mode">${{ $formatPrice(item.Fee_6) }}</div>
      <div class="text-right-mode">${{ $formatPrice(item.Fee_7) }}</div>
      <div class="text-right-mode">${{ $formatPrice(item.Fee_8) }}</div>
      <div class="text-right-mode">${{ $formatPrice(item.Fee_10) }}</div>
      <div class="text-right-mode">${{ $formatPrice(item.Fee_11) }}</div>
      <div class="text-right-mode">${{ $formatPrice(item.Fee_12) }}</div>
      <div class="text-right-mode">${{ $formatPrice(item.Subtotal) }}</div>
      <div></div>
      <!-- <div></div> -->
    </main>
    <footer class="dtc-footer-total">
      <div class="dtx-count">合計</div>
      <section class="dtc-s1" style="border:none;">
        <div class="text-right-mode">${{ $formatPrice(learnSpendFooter) }}</div>
        <div class="text-right-mode">${{ $formatPrice(insureFooter) }}</div>
        <div class="text-right-mode">${{ $formatPrice(othersFooter) }}</div>
      </section>
      <section class="dtc-s2" style="border:none;">
        <div class="text-right-mode">${{ $formatPrice(eatFooter) }}</div>
        <div class="text-right-mode">${{ $formatPrice(allowFooter) }}</div>
        <div class="text-right-mode">${{ $formatPrice(classFooter) }}</div>
        <div class="text-right-mode">${{ $formatPrice(bookFooter) }}</div>
        <div class="text-right-mode">${{ $formatPrice(clothFooter) }}</div>
      </section>
      <section class="dtc-s3" style="border:none;">
        <div class="text-right-mode">${{ $formatPrice(backHomeFooter) }}</div>
        <div class="text-right-mode">${{ $formatPrice(liveFooter) }}</div>
      </section>
      <div class="text-right-mode">${{ $formatPrice(deviceFooter) }}</div>
      <div class="text-right-mode">${{ $formatPrice(sumFooter) }}</div>
      <div></div>
    </footer>
    <footer class="dtc-note">
      <div>備註</div>
      <div>1.實報實銷類項目：各款項目請於額度內依各學校實際支付數報核。</div>
      <div>2.定額核銷類項目：每項補助費不得超過該定額補助標準</div>
      <div>3.在補助額度內類項目：應檢附單據或證明始能補助及核銷。</div>
      <div>4.應屆畢業生旅行參訪費：須於公費生畢業當年度報支。</div>
      <div>
        5.返鄉旅費：應以大眾交通工具並按返鄉必經之順路計算(如住家與學校未超過30公里則不能支領交通費，超過30公里者每學期限補助一次)。
      </div>
      <div>
        6.住宿費：每人每月3,000元，須檢附租賃契約書影本，如為合租無法以學生本人簽訂者，須補附切結書。
      </div>
    </footer>
    <footer class="dtc-sign-area">
      <div>註冊組</div>
      <div>出納組</div>
      <div>會計(財務)</div>
      <div>校長</div>
      <div class="dtc-sign-area-last"></div>
    </footer>
  </section>
</template>

<script>
import queryString from "query-string";
const levels = ["一", "二", "三", "四"];
const rowObj = {};
let pageRows = new Array(100).fill().map((s, t) => {
  return {
    term: 1,
    map: "",
    myKey: t,
    s1: "",
    s2: "",
    s3: "",
    s4: "",
    cat3_1: "",
    cat3_2: "",
    cat3_3: "",
    cat6_1: "",
    cat6_2: "",
    cat6_3: "",
    cat6_4: "",
    cat6_5: "",
    cat6_6: "",
    cat2_1: "",
    cat2_2: "",
    catTotal: "",
  };
});

export default {
  name: "doc2",
  data() {
    return {
      twYears,
      term: 1,
      bYear: "",
      totalList: [],
      schoolArr: [],
      schools: [],
      levels,
      pageRows,
      pageSize: 22,
      isHeaderVisible: true,
      map: "",
      category: "",
      learnSpendFooter: 0,
      insureFooter: 0,
      othersFooter: 0,
      eatFooter: 0,
      allowFooter: 0,
      classFooter: 0,
      bookFooter: 0,
      clothFooter: 0,
      travelFooter: 0,
      backHomeFooter: 0,
      liveFooter: 0,
      deviceFooter: 0,
      sumFooter: 0,
    };
  },

  methods: {
    async save() {
      try {
        this.map.budgetings = this.map.budgetings.filter(
          (s) => Number(s.NumberOfPeople) > 0
        );
        await window.axios.put("Budgeting/Put?id=" + this.map.SeqNo, this.map);
        this.$root.$emit("dtc-save-form-ok");
      } catch (e) {
        const str = e + " API: " + url;
        this.$root.$emit("dtc-server-error", str);
      }
    },
    updateRow(item, reset) {
      // const n =
      //   Number(item.NumberOfPeople) > 0 ? Number(item.NumberOfPeople) : 0;
      // if (!reset) {
      //   item.Fee_9 > 0 ? (item.cat6_6 = "" + item.Fee_9) : "";
      //   item.Fee_10 > 0 ? (item.cat2_1 = "" + item.Fee_10) : "";
      //   item.Fee_11 > 0 ? (item.cat2_2 = "" + item.Fee_11) : "";
      // }
      // if (!n) {
      //   item.Fee_9 = item.Fee_10 = item.Fee_11 = 0;
      //   item.cat6_6 = item.cat2_1 = item.cat2_2 = "";
      // }
      // +item.cat6_6 > 0 ? "" : (item.cat6_6 = "");
      // +item.cat2_1 > 0 ? "" : (item.cat2_1 = "");
      // +item.cat2_2 > 0 ? "" : (item.cat2_2 = "");
      // item.catTotal = "";
      item.Subtotal =
        item.Fee_1 +
        item.Fee_2 +
        item.Fee_3 +
        item.Fee_4 +
        item.Fee_5 +
        item.Fee_6 +
        item.Fee_7 +
        item.Fee_8 +
        +item.Fee_9 +
        +item.Fee_10 +
        +item.Fee_11;
      if (reset) this.map = { ...this.map };
    },

    async getData(reset) {
      reset ? (this.map.budgetings = []) : "";
      const {
        schoolcode: SchoolCode = "0003",
        byear: Byear = "109",
        term = "1",
        category: Category = "N",
      } = queryString.parse(location.href.split("?")[1]);
      let id = 0;
      SchoolCode ? (id = SchoolCode) : (id = "0003");
      const { SchoolName } = this.schoolArr.find((s) => s.SchoolCode == id);
      this.schoolName = SchoolName;
      const url = `Budgeting/GetList?byear=${Byear}&term=${term}&schoolcode=${SchoolCode}&category=${Category}`;
      try {
        const map = await window.axios.get(url);
        if (map) {
          this.map = map;
          //this.map.budgetings.forEach(s => this.updateRow(s));
          //this.map = { ...this.map };

          this.map.budgeting_detail.forEach((s) => {
            //////////////////////////////////////////////////
            this.learnSpendFooter += Number(s.Fee_1) > 0 ? Number(s.Fee_1) : 0;
            this.insureFooter += Number(s.Fee_2) > 0 ? Number(s.Fee_2) : 0;
            this.othersFooter += Number(s.Fee_3) > 0 ? Number(s.Fee_3) : 0;
            ////////
            this.eatFooter += s.Fee_4 > 0 ? s.Fee_4 : 0;
            this.allowFooter += s.Fee_5 > 0 ? s.Fee_5 : 0;
            this.classFooter += s.Fee_6 > 0 ? s.Fee_6 : 0;
            this.bookFooter += s.Fee_7 > 0 ? s.Fee_7 : 0;
            this.clothFooter += s.Fee_8 > 0 ? s.Fee_8 : 0;
            this.travelFooter += s.Fee_9 > 0 ? s.Fee_9 : 0;
            /////
            this.backHomeFooter += s.Fee_10 > 0 ? s.Fee_10 : 0;
            this.liveFooter += s.Fee_11 > 0 ? s.Fee_11 : 0;
            //
            this.deviceFooter += s.Fee_12 > 0 ? s.Fee_12 : 0;
            this.sumFooter += s.Subtotal > 0 ? s.Subtotal : 0;
          });
        }
      } catch (e) {
        const str = e + " API: " + url;
        this.$root.$emit("dtc-server-error", str);
      }
    },
  },
  async mounted() {
    let schools = [];
    if (window.dtcSchools) {
      this.schoolArr = schools = window.dtcSchools;
    } else {
      this.schoolArr = schools = await window.axios.get("School/GetAll");
    }

    //for check is same school-prevent see other school
    const { schoolcode: schoolNow } = queryString.parse(
      location.href.split("?")[1]
    );

    const loginHealthCode = sessionStorage.getItem("darcok").replace(/\"/g, "");

    const stuChoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
    const roleOtalArr = JSON.parse(sessionStorage.getItem("roleOtal"));
    const isSchoolPerson = stuChoolArr.includes(loginHealthCode) ? true : false;

    const isAdminPerson = roleOtalArr.includes(loginHealthCode) ? true : false;

    if (!isSchoolPerson && !isAdminPerson) {
      return;
    }

    if (isSchoolPerson) {
      if (loginHealthCode != schoolNow) {
        return;
      }
    }
    //check Can see and then get data
    this.getData();
  },
  beforeMount() {
    this.$root.$on("dtc-save-form", () => this.save());
    const {
      schoolcode: SchoolCode = "0003",
      byear: Byear = "109",
      term = "1",
      category: Category = "N",
    } = queryString.parse(location.href.split("?")[1]);
    this.term = +term;
    Byear ? (this.bYear = Byear) : (this.bYear = "108");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  position: relative;
  --dtc-grid-header-height: 170px;
  --dtc-doc8-width: 1330px;
  --dtc-border-color: var(--dark);
  margin: 30px auto;
  min-height: 120px;
  width: var(--dtc-doc8-width);
  font-size: 12px;
  text-align: center;
}
.dtc-grid-height {
  line-height: var(--dtc-grid-header-height);
}

.dtc-grid-header8 {
  background: #fff;
  position: sticky;
  // background: var(--dark);
  top: 50px;
  display: grid;
  border: 1px solid var(--dtc-border-color);
  grid-template-columns: 36px 50px 71px 345px 375px 180px 90px 90px 90px;
  > div {
    height: var(--dtc-grid-header-height);
    border-right: 1px solid var(--dtc-border-color);
    text-align: center;
  }
  section {
    display: grid;
    position: relative;
    align-items: end;
    border-right: 1px solid var(--dtc-border-color);
    &::before,
    &::after {
      position: absolute;
      content: "實報實銷類";
      display: block;
      transform: translateX(-50%);
      width: max-content;
      height: 30px;
      font-size: 16px;
      top: 10%;
      left: 50%;
      right: 0;
      bottom: 0;
    }
    > div {
      max-height: 120px;
      padding-top: 2px;
      height: var(--dtc-grid-header-height);
      border-right: 1px solid var(--dtc-border-color);
      border-top: 1px solid var(--dtc-border-color);
    }
    > div:last-child {
      border-right: none;
    }
  }
  .dtc-heaer-s1 {
    grid-template-columns: repeat(3, 115px);
    position: relative;
    > div {
      //margin-right: -1px;
    }
    &::before {
      content: "實報實銷類";
    }
    &::after {
      content: "";
    }
    .comment-one {
      position: absolute;
      right: 31%;
      top: 12%;
    }
  }
  .dtc-heaer-s2 {
    position: relative;
    &::before {
      content: "定額核銷類";
    }
    &::after {
      content: "";
    }
    grid-template-columns: repeat(6, 75px);
    > div {
      // margin-right: -1px;
    }
    .comment-two {
      position: absolute;
      right: 32%;
      top: 12%;
    }
  }
  .dtc-heaer-s3 {
    position: relative;
    &::before {
      content: "在補助額度內檢";
      top: 2%;
    }
    &::after {
      content: "附單據或證明類";
      top: 15%;
    }
    grid-template-columns: repeat(2, 90px);
    .comment-three {
      position: absolute;
      right: 4%;
      top: 15%;
    }
    .comment-five-head {
      position: relative;
      .comment-five {
        position: absolute;
        top: 48%;
        right: 10%;
      }
    }
    .comment-six-head {
      position: relative;
      .comment-six {
        position: absolute;
        top: 30%;
        right: 5%;
      }
    }
  }
}
.dtc-sign-area {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 38px 100px;
  font-size: 24px;
  margin-top: 5px;
  > div {
    padding-top: 10px;
    // border-bottom: 1px solid var(--dtc-border-color);
    // border-right: 1px solid var(--dtc-border-color);
    border: 0px;
  }
  .dtc-sign-area-last {
    grid-column: 1/-1;
  }
}

.dtc-note {
  grid-column: 1/-1;
  text-align: left;
  display: grid;
  height: 268px;
  grid-template-columns: 1fr;
  padding: 7px;
  font-size: 16px;
  > div:not(:first-child) {
    padding-left: 9px;
  }
}

.dtc-grid-main,
.dtc-footer-total {
  display: grid;
  --height: 38px;
  border: 1px solid var(--dtc-border-color);
  border-top: none;
  height: var(--height);
  // grid-template-columns: 36px 132px 61px 40px 360px 450px 180px 90px;
  grid-template-columns: 36px 50px 71px repeat(3, 115px) repeat(5, 75px) repeat(
      4,
      90px
    );
  font-size: 16px;
  > div {
    border-right: 1px solid var(--dtc-border-color);
    line-height: 38px;
  }
  section {
    > div {
      border-right: 1px solid var(--dtc-border-color);
      line-height: 38px;
    }
  }
  > div:last-child {
    border-right: none;
  }
  .dtc-main-sec {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    border-right: 1px solid var(--dtc-border-color);
    > div {
      height: 20px;
      line-height: 21px;
      border-bottom: 1px solid var(--dtc-border-color);
    }
    > div:last-child {
      border-bottom: none;
    }
  }
}
.dtc-footer-total {
  grid-template-columns: 157px 345px 375px 180px 90px 90px 90px;
  // line-height: 38px;
  padding: 0;
  .dtx-count {
    text-align: center;
  }
}
.dtc-s1,
.dtc-s2,
.dtc-s3 {
  line-height: 28px;
  // height: 38px;
  border-right: 1px solid black;
}

.dtc-s1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.dtc-s2 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  > div {
    // padding-top: 5px;
  }
}

.dtc-s3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  > div {
    // padding-top: 5px;
  }
}
.dtc-title {
  display: inline-block;
  margin: auto;
  font-size: 16px;
  width: 1238px;
}

.input-group-prepend .input-group-text {
  min-width: 150px;
}

.dtc-red-dot {
  position: relative;
  &::before {
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    top: -10px;
    left: 12px;
    content: "*";
    color: red;
    font-size: 24px;
  }
}

.dtc-fill-requiredxx,
.dtc-fill-required2xxx {
  position: relative;
  &::after {
    position: absolute;
    font-size: 16px;
    color: red;
    content: "*";
    top: -9px;
    left: 25px;
  }
}
.text-right-mode {
  text-align: right;
  padding-right: 5px;
}

@media print {
  #app .input-group-prepend .input-group-text {
    background: white;
    color: black;
  }
  #app .input-group-append .input-group-text {
    background: white;
    color: black;
  }
  #app {
    .input-group input,
    .input-group-text input,
    input,
    textarea,
    select,
    textarea:focus .input-group select {
      background: white;
      color: black;
    }
  }
}

@media print {
  .dtc-main-section {
    --dtc-border-color: var(--dark) !important;
    width: var(--dtc-doc8-width);
    position: relative;
    //margin: 3px auto 60px auto;
    width: var(--dtc-doc8-width);
    color: black !important;
  }
  @page {
    size: A4 landscape;
  }
}
</style>
